// $$primary, $primary_fade from "./colours.scss";
@import "colours.scss";

.GameMap {
  // height: calc(100vh - 50px);
  width: 100%;
  /* border: 1px solid black; */
}

.GameMap svg {
  /* background-color: azure; */
  background-color: rgb(255, 253, 228);
}

// Flipping the colours for owned and not owned, bc you want your own base to be solid
.Base {
  stroke: $primary_fade;
  fill: $primary_fade;
}

.Base.Owned {
  stroke: $primary;
  fill: $primary;
}

.Base rect {
  /* stroke: $primary; */
  fill: transparent;
  stroke-width: 4;
  stroke-linejoin: round;
}

.Unit {
  /* font-weight: 700;
    letter-spacing: 0.5px; */
  stroke: $primary;
  fill: $primary;
}

.Unit.Owned {
  stroke: $primary_fade;
  fill: $primary_fade;
}

.Unit rect {
  fill: transparent;
  stroke-width: 2;
}

.Word {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 1px;
  stroke: $primary;
  fill: $primary;
}

.Word.Owned {
  stroke: $primary_fade;
  fill: $primary_fade;
}

.Typed {
  /* fill: red; */
  // fill: $primary_fade;
  // stroke: $primary_fade;
  fill: $error;
  stroke: $error;
}

// FIXME: Could probably do a better selector here
.Word.Owned .Typed {
  stroke: $primary_fade;
  fill: $primary_fade;
}

.Target .Word {
  font-size: 3rem;
}

.Target rect {
  fill: rgba(100%, 100%, 100%, 0.95);
  stroke: gray;
  // box-shadow: 1px 1px 1px black;
}
