// $shadow_border, shadow_border_hover from "./layout.module.scss";
@import "layout.scss";

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
  font-family: "Enriqueta";
}

* {
  box-sizing: border-box;
}

li {
  list-style-type: none;
}

a {
  color: black;
  text-decoration: none;
}

button {
  border: none;
  box-shadow: shadow_border;
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

button:hover {
  box-shadow: shadow_border_hover;
}

button:hover:disabled {
  box-shadow: shadow_border;
}

@font-face {
  font-family: "Futura Std Book";
  src: url("./fonts/Futura Std Book.ttf");
}

@font-face {
  font-family: "Futura Thin";
  src: url("./fonts/Futura_Thin.ttf");
}

@font-face {
  font-family: "Enriqueta";
  src: url("./fonts/Enriqueta-Regular.otf");
}

@font-face {
  font-family: "Enriqueta Bold";
  src: url("./fonts/Enriqueta-Bold.otf");
}
