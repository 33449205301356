// $$nav_height, $side_padding from "./layout.module.scss";
@import "layout.scss";
// $$primary from "./colours.scss";
@import "colours.scss";

.Nav {
  height: $nav_height;
  display: flex;
  align-items: center;
  padding: 0px $side_padding;

  border-top: 4px solid $primary;
}

.Nav p {
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  font-family: "Futura Thin";
}

.Nav p span {
  /* font-size: 1.1rem; */
}
