$nav_height: 50px;

$subnav_height: 50px;

$side_padding: 8px;

$element_padding: 8px 12px;

$shadow_border: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);

$shadow_border_hover: 0 2px 6px 0 hsla(0, 0%, 0%, 0.5);
